<template>
  <div id="smartai_item_page" style="margin: 20px" v-cloak>
    <el-dialog
      title="设置评分点及标准答案"
      width="1200px"
      top="5vh"
      :visible="ratingPointDialogShow"
      :before-close="beforeCloseRatingPointDialog"
    >
      <div style="padding: 22px 30px 0 0; border: 1px solid #ddd">
        <div
          class="add-sub-item"
          v-for="(item, index) in answerData"
          :key="index"
        >
          <div class="add-sub-item-ctt">
            <span class="required">基础组件：</span>
            <el-select
              style="width: 150px; margin-right: 10px"
              v-model="item.answer_key"
              @change="handleAnswerKeyChange(item, $event)"
            >
              <el-option
                v-for="scoringPoint in scoringPointsList"
                :key="scoringPoint.id"
                :label="scoringPoint.name"
                :value="String(scoringPoint.id)"
                :disabled="selectedKeyValArr.includes(scoringPoint.id)"
              ></el-option>
            </el-select>
            <div class="add-sub-item-ans">
              <div
                v-for="(base_item, base_index) in item.answer_set"
                :key="base_index"
              >
                <el-row>
                  <el-col :span="16">
                    <div class="grid-content">
                      <div v-if="base_item.base_name == 'judges_score'">
                        最大分数：<el-input
                          style="width: 120px"
                          v-model="base_item.params.attributes.max_score"
                        ></el-input>
                        默认评语:
                        <el-input
                          style="width: 120px"
                          v-model="base_item.params.attributes.default_comments"
                        ></el-input>
                      </div>
                      <div v-if="base_item.base_name == 'average_score'">
                        设置平均数量：<el-input
                          style="width: 120px"
                          v-model="base_item.params.attributes.average_num"
                        ></el-input>
                      </div>
                      <div v-if="base_item.base_name == 'time_score'">
                        设置时长：<el-input
                          style="width: 160px"
                          v-model="base_item.params.attributes.time"
                        >
                          <template slot="append">
                            <el-select
                              style="width: 80px"
                              v-model="base_item.params.attributes.unit"
                            >
                              <el-option
                                :value="un_item"
                                v-for="(un_item, un_index) in time_arr"
                                :key="un_index"
                                >{{ un_item }}</el-option
                              >
                            </el-select></template
                          >
                        </el-input>
                        <br />
                        <el-checkbox
                          true-label="1"
                          false-label="0"
                          v-model="base_item.params.attributes.immediately_exce"
                          >是否立即执行</el-checkbox
                        >
                        设置执行次数：<el-input
                          style="width: 160px"
                          v-model="base_item.params.attributes.exec_num"
                        ></el-input>
                        <span
                          style="
                            font-size: 12px;
                            color: #d3dce6;
                            position: absolute;
                          "
                          >0表示不限次数</span
                        >
                      </div>
                      <div v-if="base_item.base_name == 'regular_score'">
                        选择正则:
                        <el-select
                          style="width: 100px; margin-right: 10px"
                          v-model="base_item.params.attributes.chose_regular"
                        >
                          <el-option
                            :value="reg_item.value"
                            :key="reg_index"
                            :label="reg_item.label"
                            v-for="(reg_item, reg_index) in regular_arr"
                            >{{ reg_item.label }}</el-option
                          >
                        </el-select>
                        <el-checkbox
                          true-label="1"
                          false-label="0"
                          v-model="base_item.params.is_required"
                          >错误立即中止</el-checkbox
                        >
                      </div>
                      <div v-if="base_item.base_name == 'order_percent_score'">
                        设置比例：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.percent"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </div>
                      <div
                        style="width: 70%"
                        v-if="base_item.base_name == 'range_score'"
                      >
                        设置范围
                        <el-input
                          style="width: 160px"
                          v-model="base_item.params.attributes.min_value"
                        ></el-input>
                        -<el-input
                          style="width: 160px"
                          v-model="base_item.params.attributes.max_value"
                        ></el-input>
                        <el-select
                          style="width: 80px"
                          v-model="base_item.params.attributes.symbol_type"
                        >
                          <el-option value="0" label="不带等号"
                            >不带等号</el-option
                          >
                          <el-option value="1" label="右边等号"
                            >右边等号</el-option
                          >
                          <el-option value="2" label="左边等号"
                            >左边等号</el-option
                          >
                          <el-option value="3" label="两边等号"
                            >两边等号</el-option
                          >
                        </el-select>
                      </div>
                      <div v-if="base_item.base_name == 'bank_score'">
                        <div
                          v-for="(un_item, un_index) in base_item.params
                            .attributes"
                          :key="un_index"
                        >
                          <div>
                            排名：<el-input
                              size="mini"
                              style="width: 245px"
                              v-model.trim="un_item.bank_num"
                            ></el-input>
                          </div>
                          <div>
                            分数：
                            <el-input
                              size="mini"
                              style="width: 245px"
                              v-model.trim="un_item.score"
                              @input="
                                syncBankScore(index, base_index, un_index)
                              "
                            ></el-input>
                          </div>
                          <el-button
                            size="mini"
                            circle
                            type="danger"
                            icon="el-icon-minus"
                            v-show="base_item.params.attributes.length > 1"
                            @click="delBankPoint(index, base_index, un_index)"
                          ></el-button>
                          <el-button
                            size="mini"
                            circle
                            type="success"
                            icon="el-icon-plus"
                            v-if="
                              un_index ===
                              base_item.params.attributes.length - 1
                            "
                            @click="addbankPoint(index, base_index)"
                          ></el-button>
                        </div>
                      </div>
                      <div v-if="base_item.base_name == 'mini_score'">
                        设置最低分：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.mini_score"
                          @input="syncMiniScore(index, base_index)"
                        >
                        </el-input>
                      </div>
                      <div v-if="base_item.base_name == 'collection_score'">
                        设置值：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.text"
                        >
                        </el-input>
                      </div>
                      <div v-if="base_item.base_name == 'customize_score'">
                        设置自定义函数：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.function_name"
                        >
                        </el-input>
                      </div>
                      <div v-if="base_item.base_name == 'image_score'">
                        设置最小宽度：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.min_width"
                        >
                          <template slot="append">像素</template>
                        </el-input>
                        设置最小高度：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.min_height"
                        >
                          <template slot="append">像素</template>
                        </el-input>
                        设置最小大小：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.min_size"
                        >
                          <template slot="append">B</template>
                        </el-input>
                      </div>
                      <div v-if="base_item.base_name == 'video_score'">
                        <span style="width: 100px; display: inline-block"
                          >视频大小：</span
                        >
                        <el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.min_size"
                        >
                          <template slot="append">B</template>
                        </el-input>
                        <br />
                        <span style="width: 100px; display: inline-block">
                          视频格式：</span
                        >
                        <el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.format"
                        >
                        </el-input>
                      </div>
                      <div
                        v-if="base_item.base_name == 'market_interval_score'"
                      >
                        设置分隔数:<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.divider"
                        >
                        </el-input>
                        大于最大值：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.gt_max_value"
                        >
                        </el-input>
                        大于最小值：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.gt_min_value"
                        >
                        </el-input>
                        小于最大值：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.lt_max_value"
                        >
                        </el-input>
                        小于最小值：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.lt_min_value"
                        >
                        </el-input>
                      </div>
                      <div v-if="base_item.base_name == 'answer_score'">
                        设置正确答案：<el-input
                          style="width: 200px"
                          v-model="base_item.params.attributes.standard_answer"
                        >
                        </el-input>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <div
                        v-if="
                          addData.score_type == 3 &&
                          base_item.base_name != 'regular_score'
                        "
                      >
                        <el-input
                          style="width: 120px"
                          v-model="base_item.params.score"
                        ></el-input>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="add-sub-item-right">
              <el-select
                v-if="answerData.length > 1"
                size="mini"
                v-model="item.clac_type"
                placeholder="请选择组件计算方式"
              >
                <el-option label="加分" value="0">加</el-option>
                <el-option label="减分" value="1">减</el-option>
              </el-select>
            </div>
          </div>
          <div class="add-sub-item-btns">
            <el-button
              size="mini"
              circle
              type="danger"
              icon="el-icon-minus"
              v-show="answerData.length > 1"
              @click="delPoint(index)"
            ></el-button>
            <el-button
              size="mini"
              circle
              type="success"
              icon="el-icon-plus"
              v-if="index === answerData.length - 1"
              @click="addPoint"
            ></el-button>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="beforeCloseRatingPointDialog">取 消</el-button>
        <el-button type="primary" @click="saveBasicByRating">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="800px"
      top="5vh"
      :title="scoreSelectDialogTitle"
      :visible="scoreSelectDialogShow"
      :before-close="beforeCloseScoreSelectDialog"
    >
      <el-form
        ref="scoreSelectInfoForm"
        label-width="145px"
        size="small"
        style="padding-right: 50px"
        :model="addData"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="评分项名称" prop="name" key="addData.name">
              <el-input
                v-model="addData.name"
                maxlength="100"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评分项标识" prop="sign" key="addData.sign">
              <el-input
                v-model="addData.sign"
                maxlength="100"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属学校" prop="school_id">
              <el-select
                v-model="addData.school_id"
                placeholder="请选择所属学校"
                clearable
              >
                <el-option
                  v-for="(s_item, s_index) in school_list"
                  :key="s_index"
                  :label="s_item.school_name"
                  :value="s_item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评分方式" prop="score_type">
              <el-select
                v-model="addData.score_type"
                @change="handleScoreTypeKeyChange"
              >
                <el-option
                  v-for="(st_item, st_index) in score_type_arr"
                  :key="st_index"
                  :label="st_item"
                  :value="st_index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="选择分类">
              <el-cascader
                v-model="addData.parent_arr"
                :options="cateTableData"
                :props="optionProps"
                style="width: 100%"
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="addData.score_type != 3 && addData.score_type != 4"
          >
            <el-form-item label="分值" prop="score" key="addData.score">
              <el-input v-model="addData.score" style="width: 100%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          v-if="addData.score_type == 5"
          label="平均数量"
          prop="average_num"
          key="addData.average_num"
        >
          <el-input
            v-model="addData.average_num"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="细则说明" prop="desc" key="addData.desc">
          <el-input v-model="addData.desc" type="textarea" :rows="4"></el-input>
        </el-form-item>
        <el-form-item label="评分点及答案设置" key="addData.answer">
          <el-link
            type="primary"
            style="line-height: 24px"
            @click="handleClickAnswerSet"
            >点击设置评分点及标准答案</el-link
          >
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="排序" prop="order_num" key="addData.order_num">
              <el-input v-model="addData.order_num"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="关联">
          <el-select v-model="addData.against_type">
            <el-option
              v-for="(at_item, at_index) in against_arr"
              :key="at_index"
              :label="at_item"
              :value="at_index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="组件状态" prop="status">
          <el-switch
            style="margin-top: 5px"
            v-model="addData.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="beforeCloseScoreSelectDialog"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="saveBasic"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <div id="toolbar">
      <el-button size="medium" type="primary" @click="addBasic"
        >新增评分项</el-button
      >
    </div>
    <div id="table" style="margin-top: 10px">
      <el-table :data="itemTableData" style="width: 100%; padding: 10px">
        <el-table-column prop="id" label="编号" width="50"> </el-table-column>
        <el-table-column prop="name" label="组件名称" width="150">
        </el-table-column>
        <el-table-column prop="sign" label="组件标识" width="180">
        </el-table-column>
        <el-table-column prop="school_text" label="学校"> </el-table-column>
        <el-table-column prop="cate_name" label="分类"> </el-table-column>
        <el-table-column prop="desc" label="描述" width="300">
        </el-table-column>
        <el-table-column prop="score" label="分数" width="50">
        </el-table-column>
        <el-table-column prop="score_type_text" label="评分方式" width="80">
        </el-table-column>
        <el-table-column prop="status" label="状态" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success">正常</el-tag>
            <el-tag v-if="scope.row.status == 0" type="danger">关闭</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditBasic(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelBasic(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px"
        background
        @current-change="changePagetoItem"
        :page-size="item_pagesize"
        :total="item_totalcount"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getSchoolList,
  getBasicList,
  getCateList,
  getScoringPointsList,
  saveBasic,
  delBasic,
  getConfigData,
} from "@/api/admin.js";
export default {
  data() {
    return {
      // 弹窗标签
      scoreSelectDialogTitle: "新增评分项",
      // 弹窗的打开
      scoreSelectDialogShow: false,
      // 添加数据
      addData: {
        id: 0,
        name: "",
        sign: "",
        school_id: "",
        parent_arr: [],
        cate_id: 0,
        against_type: 0,
        average_num: 0,
        desc: "",
        score: 0,
        score_type: 0,
        status: 1,
        order_num: 0,
        score_point: {},
      },
      // 学校数据
      school_list: [],
      // 评分方式
      score_type_arr: [],
      against_arr: [],
      regular_arr: [],
      time_arr: [],
      // 类别的数据
      cateTableData: [],
      // 类别的弹窗属性
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        expandTrigger: "click",
      },
      // 评分项表格的相关属性
      itemTableData: [],
      item_page: 1,
      item_pagesize: 10,
      item_totalcount: 0,
      // 评分点弹窗
      ratingPointDialogShow: false,
      // 评分点的答案设置
      answerData: [
        {
          answer_key: "",
          answer_sign: "",
          answer_set: [],
          clac_type: 0,
        },
      ],
      answer_templete: {
        answer_key: "",
        answer_sign: "",
        answer_set: [],
        clac_type: 0,
      },
      bank_templete: {
        bank_num: "",
        score: 0,
      },
      scoringPointsList: [],
      selectedKeyValArr: [],
      // 读取相关插件的数据
      answerdetails: [],
    };
  },
  watch: {
    "addData.parent_arr": function (newValue, oldValue) {
    
      this.addData.cate_id = newValue[newValue.length - 1];
    },
  },
  methods: {
    $Ascending: function (property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },
    syncBankScore: function (index, set_index, un_index) {

      let temp = JSON.parse(
        JSON.stringify(
          this.answerData[index].answer_set[set_index].params.attributes
        )
      );
      temp.sort(this.$Ascending("score"));
      this.answerData[index].answer_set[set_index].params.score =
        temp[0]["score"];
    },
    syncMiniScore: function (index, set_index) {
      this.answerData[index].answer_set[set_index].params.score =
        this.answerData[index].answer_set[
          set_index
        ].params.attributes.mini_score;
    },
    handleDelBasic: function (id) {
      let vm = this;
      vm.$confirm("确认要删除评分组件?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delBasic({
            delete_id: id,
          }).then((res) => {
            if (res.code == 1) {
              vm.$message({
                type: "success",
                message: "删除成功",
              });
              vm.getBasicList();
            }
          });
        })
        .catch(() => {
          vm.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleScoreTypeKeyChange: function () {
      if (this.addData.score_type != 2) {
        // 表示根据答案进行设置平均分
        this.addData.average_num = 0;
      }
    },
    delBankPoint: function (index, set_index, u_index) {
      this.answerData[index].answer_set[set_index].params.attributes.splice(
        u_index,
        1
      );
    },
    addbankPoint: function (index, set_index) {

      this.answerData[index].answer_set[set_index].params.attributes.push(
        JSON.parse(JSON.stringify(this.bank_templete))
      );
    },
    saveBasicByRating: function () {
      this.addData.score_point = JSON.parse(JSON.stringify(this.answerData));
      this.beforeCloseRatingPointDialog();
    },
    handleAnswerKeyChange: function (obj, keyval) {
      //   let vm = this;

      obj.answer_set = [];
      // 获取这个基础插件的详情
      if (this.scoringPointsList.length > 0) {
        this.scoringPointsList.forEach((element) => {
          if (element.id == keyval) {
            element.base_point.forEach((bp_element) => {
              bp_element.point.field_json.forEach((bpa_item) => {
     
                // let answer_set_obj={
                //   'name':bp_element.point.name,
                //   'detail':JSON.parse(JSON.stringify(bpa_item))
                // }
                obj.answer_set.push(JSON.parse(JSON.stringify(bpa_item)));
              });
            });
            obj.answer_sign = element.sign;
          }
        });
      }
      this.selectedKeyValArr.push(parseInt(keyval));
      let arr = [];
      this.selectedKeyValArr.forEach((item) => {
        if (arr.indexOf(item) == -1) {
          arr.push(item);
        }
      });
      this.selectedKeyValArr = arr;
    },
    delPoint: function (index) {
      this.answerData.splice(index, 1);
    },
    addPoint: function () {
      this.answerData.push(JSON.parse(JSON.stringify(this.answer_templete)));
    },
    beforeCloseRatingPointDialog: function () {
      this.ratingPointDialogShow = false;
    },
    handleClickAnswerSet() {
      let vm = this;
      let { sign, score_type } = this.addData;
      sign = sign.trim();

      if (!sign) {
        this.$message.error("请填写评分项标识");
      } else if (parseInt(score_type) < 0) {
        this.$message.error("请选择评分方式");
      } else {
        // 获取相关评分项的基础组件

        getScoringPointsList({
          select_sign: sign,
          is_page: 0,
        }).then((res) => {
          if (res.code == 1) {
            vm.scoringPointsList = res.data;
          }
        });

        this.ratingPointDialogShow = true;
      }
    },
    handleEditBasic: function (arr) {
      let vm = this;
      vm.addData.id = arr["id"];
      this.addData.name = arr["name"];
      this.addData.sign = arr["sign"];
      if (arr["school_id"] == 0) {
        this.addData.school_id = "";
      } else {
        this.addData.school_id = arr["school_id"];
      }
      this.addData.parent_arr = arr["parent_arr"];
      this.addData.desc = arr["desc"];
      this.addData.score = arr["score"];
      this.addData.score_type = arr["score_type"];
      this.addData.status = arr["status"];
      this.addData.order_num = arr["order_num"];
      this.addData.average_num = arr["average_num"];
      this.addData.against_type = arr["against_type"];
      this.addData.score_point = JSON.parse(JSON.stringify(arr["score_point"]));
      if (arr["score_point"].length > 0) {
        this.answerData = JSON.parse(JSON.stringify(arr["score_point"]));
      } else {
        this.answerData = [
          {
            answer_key: "",
            answer_sign: "",
            answer_set: [],
            clac_type: 0,
          },
        ];
      }

      this.scoreSelectDialogShow = true;
    },
    // 表格分页分页切换
    changePagetoItem: function (page) {
      this.item_page = page;
      this.getBasicList();
    },
    // 获取所有的组件
    getBasicList: function () {
      let vm = this;

      getBasicList({
        page: vm.item_page,
        page_size: vm.item_pagesize,
        is_page: 1,
      }).then((res) => {
        if (res.code == 1) {
          vm.itemTableData = res.data.data;
          vm.item_totalcount = res.data.total;
        }
      });
    },
    saveBasic: function () {
      let vm = this;
      saveBasic({
        ...vm.addData,
      }).then((res) => {
        if (res.code == 1) {
          vm.beforeCloseScoreSelectDialog();
          vm.getBasicList();
        }
      });
    },
    beforeCloseScoreSelectDialog: function () {
      this.scoreSelectDialogShow = false;
    },
    //  获取所有的分类
    getCateList: function () {
      let vm = this;
      getCateList().then((res) => {
        if (res.code == 1) {
          vm.cateTableData = res.data;
        }
      });
    },
    // 打开新增评分项的弹窗
    addBasic: function () {
      this.addData = {
        id: 0,
        name: "",
        sign: "",
        school_id: "",
        parent_arr: [],
        cate_id: 0,
        against_type: 0,
        average_num: 0,
        desc: "",
        score: 0,
        score_type: 0,
        status: 1,
        score_point: {},
      };
      this.answerData = [
        {
          answer_key: "",
          answer_set: [],
          answer_sign: "",
          clac_type: 0,
        },
      ];
      this.scoreSelectDialogTitle = "新增评分项";
      this.scoreSelectDialogShow = true;
    },
    // 获取所有学校
    getSchoolList: function () {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.school_list = res.data;
        }
      });
    },
    getConfigData() {
      getConfigData().then((res) => {
        if (res.code == 1) {
          this.score_type_arr = res.data.score_type_arr;
          this.against_arr = res.data.against_info;
          this.time_arr = res.data.time_arr;
          this.regular_arr = res.data.regular_arr;
        }
      });
    },
  },
  created: function () {
    this.getConfigData();
    this.getSchoolList();
    this.getCateList();
    this.getBasicList();
  },
};
</script>
<style lang="less" scoped>
/* 使用属性选择器 */
[v-cloak] {
  display: none !important;
}
.add-sub-item {
  margin: 0 0 22px 15px;
  border-radius: 5px;
}
.add-sub-item-ctt,
.add-sub-item-btns {
  display: inline-block;
  vertical-align: top;
}
.add-sub-item-ctt {
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 90%;
}
.add-sub-item-ctt span {
  margin-left: 10px;
}
.add-sub-item-ans {
  display: inline-block;
  width: 55%;
  vertical-align: middle;
}
.add-sub-item-btns {
  margin: 5px 0 0 10px;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.add-sub-item-right {
  float: right;
}
</style>